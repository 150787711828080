<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>

        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  outlined
                  label="Emotion Title"
                  class="mr-btm-minus"
                  :rules="titleRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="opposite"
                  :items="allEmotions.results"
                  item-text="name"
                  item-value="id"
                  label="Opposite Emotion"
                  filled
                  return-object
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="icon"
                  :items="icons"
                  label="Icon"
                  filled
                  return-object>
                  <template #selection="{ item }">
                    <v-avatar left>
                      <span class="material-icons">{{ item }}</span>
                    </v-avatar>
                  </template>
                  <template #item="{ item }">
                    <v-avatar left>
                      <span class="material-icons">{{ item }}</span>
                    </v-avatar>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6" v-if="id">
                <v-text-field
                  v-model="sort"
                  outlined
                  label="Sort value"
                  class="mr-btm-minus"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-color-picker
                  v-model="color"
                  label="Color"
                  outlined
                ></v-color-picker>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadingCurd"
                >
                  {{ headercontent.title }}
                </v-btn>
                <v-progress-circular
                  v-if="allLoadingCurd"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import supportedIcons from "@/supportedIcons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddEmotion",
  components: { PageHeader },
  data: () => ({
    valid: true,
    name: "",
    opposite: null,
    sort: 0,
    icon: "face",
    color: "#ff0000",
    headercontent: {
      title: "Add Emotion",
    },
    titleRules: [(v) => !!v || "Emotion name is required"],
    icons: supportedIcons,
    id: null,
  }),
  methods: {
    ...mapActions(["fetchEmotions", "addEmotion", "fetchEmotionById", "updateEmotion"]),
    validate() {
      if (this.$refs.form.validate()) {
        const data = {
          name: this.name,
          icon: this.icon,
          color: this.color,
          oppositeId: this.opposite ? this.opposite.id : null,
        }
        if (!this.id) {
          this.addEmotion(data);
        } else {
          this.updateEmotion({ 
            id: this.id, 
            data: {
              ...data,
              sort: parseInt(this.sort, 10),
            },
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "currentEmotion", "allEmotions"]),
  },

  async created() {
    await this.fetchEmotions({ sort: 'name' });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      await this.fetchEmotionById(this.id);
      this.name = this.currentEmotion.name;
      this.icon = this.currentEmotion.icon;
      this.color = this.currentEmotion.color || "#ffffff";
      this.sort = this.currentEmotion.sort || 0;
      this.opposite = this.currentEmotion.opposite;
      this.headercontent.title = "Update Emotion";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}
</style>
